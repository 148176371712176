import styled from 'styled-components';
import colours from '../../styles/colours';

const Button = styled.button`
  color: ${colours.granite};
  border: solid 1px ${colours.granite};
  background-color: transparent;
  border-radius: 3px;
  cursor: pointer;
  margin-left: 5px;

  :hover {
    border-color: ${colours.aqua};
    color: ${colours.aqua};
  }

  :active {
    border-color: ${colours.emerald};
    color: ${colours.emerald};
  }
`;

export default Button;
