import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

interface IProps {
  auth: any,
}

class Login extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.login = this.login.bind(this);
  }

  login() {
    this.props.auth.login();
  }

  render() {
    if (this.props.auth.isAuthenticated()) {
      return <Redirect to='/' />;
    } else {
      return <button onClick={this.login}>Login</button>;
    }
  }
};

export default Login;
