import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  overflow: hidden;
`;

export const ContentWrapper = styled.div`
  padding: 50px 0 20px;
  height: 100%;
  box-sizing: border-box;
  flex: 1;
  overflow: auto;
`;
