import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

interface IProps {
  auth: any,
}

class Logout extends Component<IProps> {
  constructor(props: IProps) {
    super(props);

    this.logout = this.logout.bind(this);
  }

  logout() {
    this.props.auth.logout();
    return <Redirect to='/login' />;
  }

  render() {
    return this.logout();
  }
};

export default Logout;
