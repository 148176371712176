import React, { Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import uuidv4 from 'uuid/v4';
import moment from 'moment';
import queryString from 'query-string';

import {
  createNewItem,
  deleteItem,
} from '../../fetchers/items';

import Item from '../common/Item';
import Screen from '../common/Screen';
import Toolbar from '../common/Toolbar';
import {
  QuickTypeBar,
  ItemContainer,
} from './styles';

const sortMethods = {
  ALPHABETICAL: 'alphabet',
  CREATED_TIME: 'created',
  UPDATED_TIME: 'updated',
};

const sortLabels: { [x: string]: string } = {
  'created_asc': 'Oldest items first',
  'created_desc': 'Newest items first',
  'updated_asc': 'Least recently updated first',
  'updated_desc': 'Recently updated first',
  'alphabet_asc': 'Alphabetical',
  'alphabet_desc': 'Backwards alphabetical',
}

type PathParamsType = {
  sort: string,
}

type IProps = RouteComponentProps<PathParamsType> & {
  someString: string,
  items: any[],
  userId: string,
  reload: () => void,
}

interface IState {
  currentItemText: string,
  items: any[],
  sort?: string,
}

class Inventory extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      currentItemText: '',
      items: props.items,
    };

    this.onChangeInput = this.onChangeInput.bind(this);
    this.onEnterItem = this.onEnterItem.bind(this);
    this.onDeleteItem = this.onDeleteItem.bind(this);
    this.sortItems = this.sortItems.bind(this);
    this.filterItems = this.filterItems.bind(this);
    this.changeSortMethod = this.changeSortMethod.bind(this);
  }

  componentDidMount() {
    this.getQueryParams();
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.getQueryParams();
    }

    if (!prevProps.items && !this.props.items) { return; }
    const changed = (!prevProps.items && this.props.items)
      || prevProps.items.length !== this.props.items.length
      || this.props.items.some((item, index) => (
          item.description !== prevProps.items[index].description
        ));

    if (changed) {
      this.setState({ items: this.props.items });
    }
  }

  getQueryParams() {
    const queryParams: any = queryString.parse(this.props.location.search);

    if (!queryParams.sort) {
      queryParams.sort = 'created_desc';
    }

    this.setState({ sort: queryParams.sort });

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: queryString.stringify(queryParams),
    });
  }

  onChangeInput(e: any) {
    e.preventDefault();
    this.setState({ currentItemText: e.target.value });
  }

  onEnterItem(e: any) {
    e.preventDefault();
    const { userId, reload } = this.props;

    // Create item with client-generated uuid so it can be used immediately
    // and does not rely on response from server to display the updated ui
    const { items } = this.state;
    const newItemsList = [...items];
    const item = {
      description: this.state.currentItemText,
      id: uuidv4(),
      user_id: userId,
    };
    newItemsList.unshift({ ...item, labels: [] });
    this.setState({ items: newItemsList, currentItemText: '' });


    // Then send the info to the api and update the item's details on response
    createNewItem(item)
      .then(() => reload());
  }

  onDeleteItem(e: any, id: string) {
    if (e) { e.stopPropagation(); }

    // Immediately remove item from the UI
    const { items } = this.state;
    const filteredItems = items.filter((item) => item.id !== id);
    this.setState({ items: filteredItems });

    // Then tell the api to delete it
    const { reload } = this.props;
    deleteItem(id).then(() => reload());
  }

  sortItems() {
    const { sort } = this.state;

    if (!sort) { return (a: any, b: any) => 1; }

    const [method, direction] = sort.split('_');

    if (method === sortMethods.ALPHABETICAL) {
      return direction === 'asc'
        ? (a: any, b: any) => a.description.toLowerCase().localeCompare(b.description.toLowerCase())
        : (a: any, b: any) => b.description.toLowerCase().localeCompare(a.description.toLowerCase());
    }

    if (method === sortMethods.CREATED_TIME) {
      return direction === 'asc'
        ? (a: any, b: any) => {
          const createdTimeA = moment(a.created_at);
          const createdTimeB = moment(b.created_at);
          if (createdTimeA.isBefore(createdTimeB)) { return -1; }
          if (createdTimeA.isAfter(createdTimeB)) { return 1; }
          return 0;
        }
        : (a: any, b: any) => {
          const createdTimeA = moment(a.created_at);
          const createdTimeB = moment(b.created_at);
          if (createdTimeA.isAfter(createdTimeB)) { return -1; }
          if (createdTimeA.isBefore(createdTimeB)) { return 1; }
          return 0;
        };
    }

    if (method === sortMethods.UPDATED_TIME) {
      return direction === 'asc'
        ? (a: any, b: any) => {
          const updatedTimeA = moment(a.updated_at);
          const updatedTimeB = moment(b.updated_at);
          if (updatedTimeA.isBefore(updatedTimeB)) { return -1; }
          if (updatedTimeA.isAfter(updatedTimeB)) { return 1; }
          return 0;
        }
        : (a: any, b: any) => {
          const updatedTimeA = moment(a.updated_at);
          const updatedTimeB = moment(b.updated_at);
          if (updatedTimeB.isBefore(updatedTimeA)) { return -1; }
          if (updatedTimeB.isAfter(updatedTimeA)) { return 1; }
          return 0;
        };
    }

    return (a: any, b: any) => 1;
  }

  filterItems(item: any) {
    return item;
  }

  changeSortMethod(e: any) {
    const queryParams = queryString.parse(this.props.location.search);

    queryParams.sort = e.target.value;

    const query = queryString.stringify(queryParams);

    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: query,
    });

    this.setState({ sort: e.target.value });
  }

  render() {
    const {
      currentItemText,
      items,
      sort,
    } = this.state;
    const { reload, location } = this.props;

    const sortMethod = this.sortItems();

    return (
      <Screen>
        <form id='create-item' onSubmit={this.onEnterItem}>
          <QuickTypeBar
            value={currentItemText}
            onChange={this.onChangeInput}
            placeholder='Get things off your mind...'
          />
        </form>

        <Toolbar>
          <span />
          <span>
            <select name='sort-dropdown' value={sort} onChange={this.changeSortMethod}>
              {Object.keys(sortLabels).map((sortKey: string) => (
                <option key={sortKey} value={sortKey}>
                  {sortLabels[sortKey]}
                </option>
              ))}
            </select>
          </span>
        </Toolbar>

        <ItemContainer>
          {items ? items
            .sort(sortMethod)
            .filter(this.filterItems)
            .map((item, index) => (
              <Item
                key={item.id}
                item={item}
                labels={[]}
                deleteItem={(e) => this.onDeleteItem(e, item.id)}
                reload={reload}
                location={location}
              />
            )) : <div>Loading...</div>}
        </ItemContainer>
      </Screen>
    );
  }
}

export default withRouter(Inventory);
