import React, { Component } from 'react';

import { updateItem } from '../../../fetchers/items';
import Label from '../Label';
import {
  Wrapper,
  HiddenButton,
  ButtonContainer,
  ItemDescriptionContainer,
  ItemLink,
  ItemContent,
  LabelsContainer,
  More,
} from './styles';

interface IProps {
  item: any,
  labels: any[],
  reload: () => void,
  deleteItem: (e: any, itemId: string) => void,
  location: any,
}

interface IState {
  canEdit: boolean,
  editText: string,
}

class Item extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      canEdit: false,
      editText: props.item && props.item.description,
    };

    this.onFinishEdit = this.onFinishEdit.bind(this);
    this.handlePressEnter = this.handlePressEnter.bind(this);
  }

  onFinishEdit() {
    const { editText } = this.state;
    const { item, reload } = this.props;
    const itemToUpdate = { ...item };
    delete itemToUpdate.labels;
    updateItem({ ...itemToUpdate, description: editText })
      .then(() => reload());
    this.setState({ canEdit: false });
  }

  handlePressEnter(e: any, callback: () => void) {
    const keyCode = e.key || e.which;
    if (keyCode === 'Enter') {
      callback();
      return false;
    }
  }

  render() {
    const { canEdit, editText } = this.state;
    const {
      deleteItem,
      item = {},
      labels = [],
    } = this.props;

    return (
      <Wrapper>
        {canEdit
          ? <input
              type='text'
              value={editText}
              onChange={(e) => {
                const newEditText = e.target.value;
                this.setState({ editText: newEditText });
              }}
              onBlur={this.onFinishEdit}
              autoFocus
              onKeyPress={(e) => this.handlePressEnter(e, this.onFinishEdit)}
            />
          : (
            <ItemContent>
              <ItemDescriptionContainer>
                <ItemLink to={`inventory/${item.id}`}>
                  {item.description}
                </ItemLink>
              </ItemDescriptionContainer>
              <LabelsContainer>
                {labels
                  .filter((label, i, self) => {
                    if (self.length > 5) { return i < 4; }
                    return true;
                  })
                  .map((label) => <Label key={label.id || label} label={label} />)
                }
                {labels.length > 5 && (
                  <More to={`inventory/${item.id}`}>+{labels.length - 4} more</More>
                )}
              </LabelsContainer>
            </ItemContent>
          )}
        <ButtonContainer>
          <HiddenButton
            onClick={(e) => {
              e.stopPropagation();
              this.setState({ canEdit: true });
            }}
          >
            Edit
          </HiddenButton>
          <HiddenButton onClick={(e) => deleteItem(e, item.id)}>X</HiddenButton>
        </ButtonContainer>
      </Wrapper>
    );
  }
}

export default Item;
