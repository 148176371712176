// ts-ignore
import React from 'react';
import {
  BrowserRouter as Router,
  Route as ReactRoute,
  Redirect,
  Switch,
} from 'react-router-dom';

import Login from './Login';
import Logout from './Logout';
import Callback from './Callback';
import App from './App';

const PrivateRoute = ({ component: Component, path, exact, auth, ...rest }: { component: any, path: string, exact?: boolean, auth: any, rest?: any }) => {
  return (
    <ReactRoute
      exact={exact}
      path={path}
      render={(props) => auth.isAuthenticated()
        ? <Component {...props} {...rest} auth={auth} />
        : <Redirect to='/login'/>
      }
    />
  );
};

export const Route = ({ component: Component, path, exact, auth, ...rest }: { component: any, path: string, exact?: boolean, auth: any, reload?: (id?: string) => void, rest?: any }) => (
  <ReactRoute exact={exact} path={path} render={(props) => <Component {...props} {...rest} auth={auth} />} />
);

const Routes = ({ auth }: { auth: any }) => (
  <Router>
    <Switch>
      <Route path='/login' component={Login} auth={auth} />
      <Route path='/logout' component={Logout} auth={auth} />
      <Route path='/callback' component={Callback} auth={auth} />
      <PrivateRoute path='/' component={App} auth={auth} />
    </Switch>
  </Router>
);

export default Routes;
