import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

interface IProps {
  auth: any,
  location?: any,
}

interface IState {
  loaded: boolean,
}

class Callback extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  handleAuthentication(href: string) {
    if (/access_token|id_token|error/.test(href)) {
      this.props.auth.handleAuthentication(() => {
        this.setState({ loaded: true });
      });
    } else {
      return <Redirect to='/login' />;
    }
  }

  render() {
    const { location } = this.props;
    const { loaded } = this.state;

    if (!loaded) {
      this.handleAuthentication(`${location.pathname}${location.hash}`);
    }

    return loaded
      ? <Redirect to='/' />
      : <div>Loading...</div>;
  }
}

export default Callback;
