import styled from 'styled-components';
import colours from '../../styles/colours';

export const QuickTypeBar = styled.input.attrs({
  type: 'text',
  name: 'item-input',
})`
  width: 100%;
  padding: 10px;
  font-family: sans-serif;
  font-size: 18px;
  box-sizing: border-box;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid 2px ${colours.granite};
  color: ${colours.coal};

  ::placeholder {
    color: ${colours.granite};
  }
`;

export const ItemContainer = styled.div`
  min-height: 500px;
`;
