import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './components/Routes';
import * as serviceWorker from './serviceWorker';
import Auth from './utils/Auth';

const auth = new Auth();

ReactDOM.render(<Routes auth={auth} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
