import React, { Component } from 'react';
import {
  Wrapper,
  MenuItem,
} from './styles';

interface IProps {
  location: Location,
}

class Menu extends Component<IProps> {
  render() {
    const path = this.props.location.pathname;

    return (
      <Wrapper>
        <MenuItem to='/inventory' selected={path.includes('/inventory')}>
          <span><i className="far fa-inbox"></i>Inventory</span>
        </MenuItem>
      </Wrapper>
    );
  }
};

export default Menu;
