import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colours from '../../styles/colours';

export const BackButton = styled(Link)`
  font-size: 20px;
  color: ${colours.granite};

  :hover {
    color: ${colours.aqua};
  }
`;

export const Container = styled.div`
  padding: 20px;
`;

export const ItemTitle = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const ItemDate = styled.div`
  font-size: 12px;
  font-style: italic;
  text-align: right;
  margin-bottom: 5px;
`;

export const LabelsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
