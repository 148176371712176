import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colours from '../../styles/colours';

export const Wrapper = styled.div`
  position: fixed;
  height: 50px;
  width: 100%;
  background-color: ${colours.coal};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 10px 20px;
  font-size: 18px;
  letter-spacing: 2px;
`;

export const Logo = styled(Link)`
  color: ${colours.emerald};
  text-decoration: none;
  > span  {
    color: ${colours.aqua};
  }

  i {
    margin-right: 10px;
    color: ${colours.aqua};
  }
`;

export const Logout = styled(Link)`
  color: ${colours.slate};
  text-decoration: none;
  font-size: 22px;

  :hover {
    color: ${colours.granite};
  }
`;
