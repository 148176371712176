/* colour theme:
#067c7c // Action colour
#00bab1 // Alert colour
#14191f // Header and menu active contrast
#202a36 // Menu background
#5e6c77 // Low contrast
#ffffff // High contrast
#adb4bc // Secondary action
#e5e8ef // Secondary action background
#286376 // Extras...
#92b0bc
#00434d
#7ea1a6
*/

export default {
  emerald: '#067c7c',
  aqua: '#00bab1',
  coal: '#14191f',
  dusk: '#202a36',
  teal: '#286376',
  forest: '#00434d',
  slate: '#5e6c77',
  granite: '#adb4bc',
  cloudy: '#e5e8ef',
  duckEgg: '#92b0bc',
  malachite: '#7ea1a6',
}
