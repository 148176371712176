import React, { Component } from 'react';
import moment from 'moment';

import Screen from '../common/Screen';
import Toolbar from '../common/Toolbar';
import {
  BackButton,
  Container,
  ItemTitle,
  ItemDate,
} from './styles';

interface IProps {
  items?: any[],
  match?: any,
}

interface IState {
  itemId?: string,
}

class ItemPage extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      itemId: undefined,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.setState({ itemId: match.params.id });
  }

  render() {
    const { match, items } = this.props;

    const item = items && items.find((itemNode: any) => itemNode.id === match.params.id);

    return (
      <Screen>
        <Toolbar>
          <BackButton to='/inventory'><i className='fal fa-arrow-circle-left' /></BackButton><br/>
        </Toolbar>

        <Container>
          {item ? (
            <div>
              <ItemDate>{moment(item.created_at).format('dddd, Do MMM YYYY, hh:mmA')}</ItemDate>
              <ItemTitle>{item.description}</ItemTitle>
            </div>
          ) : <div>Loading...</div>}
        </Container>
      </Screen>
    );
  }
}

export default ItemPage;
