import configuration from '../config';
import { getAccessToken } from './Auth';

const environment = process.env.NODE_ENV;
const config = configuration[environment];

export default {
  get: (endpoint: string) => requestAPI(endpoint),
  post: (endpoint: string, data: any) => requestAPI(endpoint, 'POST', data),
  put: (endpoint: string, data: any) => requestAPI(endpoint, 'PUT', data),
  patch: (endpoint: string, data: any) => requestAPI(endpoint, 'PATCH', data),
  delete: (endpoint: string) => requestAPI(endpoint, 'DELETE'),
};

const requestAPI = async (endpoint: string, method = 'GET', body?: any) => {
  const result = await fetch(`${config.apiUrl}${endpoint}`, {
    method,
    credentials: 'same-origin',
    headers: {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${getAccessToken()}`,
    },
    body: JSON.stringify(body),
  });

  return result.json();
}
