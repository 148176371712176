export default {
  development: {
    apiUrl: process.env.REACT_APP_API_URL,
  },
  production: {
    apiUrl: process.env.REACT_APP_API_URL,
  },
  test: {
    apiUrl: process.env.REACT_APP_API_URL,
  }
};
