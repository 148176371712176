import styled from 'styled-components';
import colours from '../../../styles/colours';
import { Link } from 'react-router-dom';

import Button from '../Button';

export const Wrapper = styled.div`
  cursor: pointer;
  border-bottom: solid 1px ${colours.granite};
  background-color: white;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  padding: 0 10px;

  :hover, :focus {
    button {
      opacity: 1;
    }
  }

  :first-child {
    border-top: solid 1px ${colours.granite};
  }
`;

export const ButtonContainer = styled.span`
  flex-shrink: 0;
`;

export const ItemContent = styled.div`
  height: 20px;
  display: flex;
  width: 85%;
`;

export const LabelsContainer = styled.div`
  flex: 1 0;
`;

export const ItemDescriptionContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemLink = styled(Link)`
  color: black;
  text-decoration: none;
  margin-right: 10px;
  width: 100%;
  max-width: 100%;

  :hover {
    text-decoration: underline;
  }
`;

export const HiddenButton = styled(Button)`
  opacity: 0;

  :focus {
    opacity: 1;
  }
`;

export const More = styled(Link)`
  font-size: 10px;
  text-decoration: none;
  color: black;
  white-space: nowrap;

  :hover {
    text-decoration: underline;
  }
`;
