import API from '../utils/API';
import Item from '../models/entities/Item';
import Label from '../models/entities/Label';

export const fetchAllItems = () => API.get('/items');

export const fetchItem = (id: string) => API.get(`/item/${id}`);

export const createNewItem = (item: Item | any) => API.post('/item', item);

export const deleteItem = (id: string) => API.delete(`/item/${id}`);

export const updateItem = (item: Item | any) => API.put(`/item/${item.id}`, item);

export const labelItem = (id: string, labels: Label[] | any[]) => API.put(`/item/${id}/label`, labels);

export const removeItemLabel = (itemId: string, labelId: string) => API.delete(`/remove-label/${itemId}/${labelId}`);
