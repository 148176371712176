import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { Link } from 'react-router-dom';

import Button from './Button';

const Wrapper = styled.span<{ theme: DefaultTheme, colour: string, to?: string }>`
  background-color: transparent;
  color: ${({ colour }) => colour};
  border: solid 1px ${({ colour }) => colour};
  border-radius: 2px;
  font-size: 10px;
  padding: 3px 10px;
  margin: 2px;
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;

  :hover {
    filter: brightness(110%);
  }
`;

const LabelDelete = styled(Button)<{ theme: DefaultTheme, colour: string }>`
  color: ${({ colour }) => colour};
  border-color: transparent;

  :hover {
    border-color: transparent;
  }
`;

const Label = ({
  label: { id, colour, description } = {},
  deleteMode,
  deleteLabel,
  onUpdate,
}: {
  label: any,
  deleteMode?: boolean,
  deleteLabel?: (id: string) => void,
  onUpdate?: () => void,
}) => deleteMode ? (
  <Wrapper
    id={id}
    colour={colour}
  >
    {description}
    {deleteMode && (
      <LabelDelete
        onClick={() => deleteLabel(id)}
        colour={colour}
      >X</LabelDelete>
    )}
  </Wrapper>
) : (
  <Wrapper
    id={id}
    colour={colour}
    to={`/inventory?label=${id}`}
    as={Link}
  >
    {description}
  </Wrapper>
);

export default Label;
