import styled, { DefaultTheme } from 'styled-components';
import colours from '../../styles/colours';

const ToolBar = styled.div<{ theme: DefaultTheme, children: JSX.Element[] }>`
  display: flex;
  justify-content: ${({ children }) => (
    children.filter((child) => child).length > 1 ? 'space-between' : 'flex-end'
  )}
  align-items: center;
  padding: 5px 10px;
  background-color: ${colours.cloudy};
  height: 30px;
  box-sizing: border-box;
`;

export default ToolBar;
