import React, { Component } from 'react';

import {
  Wrapper,
  Logo,
  Logout,
} from './styles';

interface IState {
  currentItemText: string,
}

class Header extends Component<any, IState> {
  constructor(props: any) {
    super(props);

    this.state = {
      currentItemText: '',
    };
  }

  render () {
    return (
      <Wrapper>
        <Logo to='/'><i className="fas fa-brain"></i>Brain<span>Dump</span></Logo>
        <Logout to='logout'><i className="far fa-power-off"></i></Logout>
      </Wrapper>
    );
  }
};

export default Header;
