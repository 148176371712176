import React, { Component } from 'react';
import { Redirect, Route as ReactRoute } from 'react-router-dom';

import { Wrapper, ContentWrapper } from './styles';
import { Route } from '../Routes';
import { fetchAllItems, fetchItem } from '../../fetchers/items';
import { fetchAllLabels } from '../../fetchers/labels';

import Header from '../Header';
import Menu from '../Menu';
import Inventory from '../Inventory';
import ItemPage from '../ItemPage';

interface IProps {
  auth: any,
  location?: Location,
}

interface IState {
  defaultLandingPage: string,
  userId?: string,
  items: any[],
  labels: any,
}

class App extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      defaultLandingPage: 'inventory',
      userId: undefined,
      items: [],
      labels: [],
    };

    this.reloadAll = this.reloadAll.bind(this);
    this.loadUserItems = this.loadUserItems.bind(this);
    this.loadUserLabels = this.loadUserLabels.bind(this);
    this.reloadItem = this.reloadItem.bind(this);
  }

  componentDidMount() {
    this.reloadAll();
  }

  reloadAll() {
    Promise.all([
      this.loadUserItems(),
      this.loadUserLabels(),
    ]);
  }

  loadUserItems() {
    fetchAllItems()
      .then(({ data, user }) => {
        this.setState({
          items: data,
          userId: user,
        });
      });
  }

  loadUserLabels() {
    fetchAllLabels()
      .then(({ data, user }) => {
        this.setState({
          labels: data,
          userId: user,
        });
      });
  }

  reloadItem(id: string) {
    const { items } = this.state;
    fetchItem(id)
      .then(({ data }) => {
        const { item, labels } = data;
        const index = items.findIndex((item) => item.id === id);
        let newItems = [];
        if (index < 0) {
          newItems = [
            ...items.slice(0),
            { ...item, labels },
          ];
        } else {
          newItems = [
            ...items.slice(0, index),
            { ...item, labels },
            ...items.slice(index + 1),
          ];
        }
        this.setState({ items: newItems });
      });
  }

  render() {
    const { auth, location } = this.props;
    const {
      items,
      labels,
      userId,
      defaultLandingPage,
    } = this.state;

    const appData = {
      auth,
      items,
      labels,
      userId,
    };

    return (
      <Wrapper>
        <Header />
        <Menu location={location} />
        <ContentWrapper>
          <div>
            <ReactRoute exact path='/' render={() => <Redirect to={`/${defaultLandingPage}`} />} />
            <Route exact path='/inventory' component={Inventory} {...appData} reload={this.reloadAll} />
            <Route path='/inventory/:id' component={ItemPage} {...appData} reload={this.reloadItem} />
          </div>
        </ContentWrapper>
      </Wrapper>
    );
  }
}

export default App;
