import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colours from '../../styles/colours';

export const Wrapper = styled.div`
  width: 180px;
  background-color: ${colours.dusk};
  display: flex;
  flex-direction: column;
  padding-top: 70px;

  @media screen and (max-width: 504px) {
    display: none;
  }
`;

export const MenuItem = styled(Link)<{ selected: boolean }>`
  margin-top: 2px;
  padding: 10px 20px;
  color: ${colours.slate};
  background-color: ${({ selected }) => (selected ? colours.coal : 'transparent')};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  :hover {
    color: ${colours.granite};
  }
  :active {
    background-color: ${colours.coal};
  }

  i {
    margin-right: 20px;
  }
`;

export const Badge = styled.span`
  background-color: ${colours.aqua};
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colours.dusk};
  font-size: 10px;
  font-weight: bold;
`;

export const ComingSoon = styled.span`
  color: ${colours.aqua};
  font-size: 7px;
`;
